import React, { Component } from 'react';
import HelmetEx from '../../components/helmet-ex';
import Layout from '../../components/layout';
import ServiceRow from '../../components/service-row';
import CornicesImage from '../../images/photos/products/cornices.png';
import DraperiesValancesImage from '../../images/photos/products/draperies-valances.png';
import DraperyHardwareImage from '../../images/photos/products/drapery-hardware.png';
import TopTreatmentsImage from '../../images/photos/products/top-treatments.png';
import SubmenuList from '../../components/submenu-list';
import Config from '../../config';

class WindowTreatments extends Component {
    render() {
        const route = Config.Services.ProductRoutes.FeaturedProjects;
        return (
            <Layout>
                <div className="product-window-treatments-page">
                    <HelmetEx>
                        <div metadata="title">Window treatments | Cornices, Draperies, Valances | Elmwood Park NJ</div>
                        <div metadata="keywords">Window, treatments,Cornices, Draperies, Valances,Elmwood Park, NJ,</div>
                        <div metadata="description">
                            Accent your fabric window treatment and complete
                            your interior or look with a variety of decorative drapery hardware styles.
                        </div>
                    </HelmetEx>
                    <section className="header">
                        <div className="container">
                            <div className="content">
                                <div className="text">
                                    <div className="row">
                                        <div className="col-12 text-center">
                                            <h1>WINDOW TREATMENTS</h1>
                                            {/* <h3></h3> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="navigation-section">
                        <div className="container">
                            <div className="row">
                                <SubmenuList activePath={route} />
                            </div>
                        </div>
                    </section>
                    <section className="below-navigation-section">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <ServiceRow flip title="CORNICES" src={CornicesImage}>
                                        <p>
                                            Add a touch of beauty over any window covering or hide room
                                            imprefections with a custom cornice.
                                        </p>
                                    </ServiceRow>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <ServiceRow title="DRAPERIES AND VALANCES" src={DraperiesValancesImage}>
                                        <p>
                                            Draperies and valances are the perfect finishing touch for you
                                            Hunter Douglas window treatments. Count on us to design the perfect
                                            soft window solution in a variety of styles, fabrics, textures and colors.
                                        </p>
                                    </ServiceRow>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section >
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <ServiceRow flip title="DRAPERY HARDWARE" src={DraperyHardwareImage}>
                                        <p>
                                            Accent your fabric window treatment and complete your interier
                                            or look with a variety of decorative drapery hardware styles.
                                        </p>
                                    </ServiceRow>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <ServiceRow title="DRAPERIES AND VALANCES" src={TopTreatmentsImage}>
                                        <p>
                                            Draperies and valances are the perfect finishing touch for you
                                            Hunter Douglas window treatments. Count on us to design the perfect
                                            soft window solution in a variety of styles, fabrics, textures and colors.
                                        </p>
                                    </ServiceRow>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </Layout>
        )
    }
}

export default WindowTreatments;